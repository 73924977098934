// src/utils/fb.js

const trackFbPageView = async () => {
    const { default: ReactPixel } = await import("react-facebook-pixel");
    ReactPixel.init(process.env.GATSBY_FACEBOOK_PIXEL_ID, null, {
        debug: true,
        autoConfig: true,
    });

    ReactPixel.pageView();
};

const trackFbPurchase = async ({ currency, value }) => {
    const { default: ReactPixel } = await import("react-facebook-pixel");
    ReactPixel.init(process.env.GATSBY_FACEBOOK_PIXEL_ID, null, {
        debug: true,
        autoConfig: true,
    });

    ReactPixel.track("Purchase", { currency: currency, value: value });
};

const trackFbAddToCart = async ({ currency, value }) => {
    const { default: ReactPixel } = await import("react-facebook-pixel");
    ReactPixel.init(process.env.GATSBY_FACEBOOK_PIXEL_ID, null, {
        debug: true,
        autoConfig: true,
    });

    ReactPixel.track("AddToCart", { currency: currency, value: value });
};

const trackFbInitiateCheckout = async ({ currency, value }) => {
    const { default: ReactPixel } = await import("react-facebook-pixel");
    ReactPixel.init(process.env.GATSBY_FACEBOOK_PIXEL_ID, null, {
        debug: true,
        autoConfig: true,
    });

    ReactPixel.track("InitiateCheckout", { currency: currency, value: value });
};

module.exports = {
    trackFbAddToCart,
    trackFbInitiateCheckout,
    trackFbPageView,
    trackFbPurchase,
};
