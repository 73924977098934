import React from "react";
import useNearScreen from "@components/hooks/useNearScreen";
import loadable from "@loadable/component";

const Testimonials = loadable(() => import("./Testimonials"));

export default function LazyTestimonials({ className, type, uid }) {
    const { isNearScreen, fromRef } = useNearScreen();

    return (
        <>
            <section ref={fromRef}>
                {isNearScreen ? <Testimonials className={className} type={type} uid={uid} /> : null}
            </section>
        </>
    );
}
