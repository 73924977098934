import React from "react";
import ProductTemplate from "./product";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
import { Link } from "gatsby";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            {/** Qué es¿? */}
            <div className="container lg:px-0 py-20">
                <Title>Curs Online per a Policia Local de Santa Coloma de Gramenet</Title>
                <Text>
                    Curs específic per preparar l'accés a la policia local de Santa Coloma de
                    Gramener 2022
                    <br />
                    <br />
                    Un curs, complementari al curs ONLINE de policia local que tenim a la web.
                    <br />
                    <br />
                    Els cursos específics de iOpos són molts complerts, ja que ens basem en els
                    patrons d'anys anteriors.
                    <br />
                    <br />
                    Són cursos fàcils d'estudiar, on tu decideixes com i quan avançar.
                    <br />
                    <br />
                    Al curs trobaràs tot el contingut necessari per posicionar-te entre els millors.
                    <br />
                    <br />
                    Un curs viu i en constant evolució.
                    <br />
                    <br />
                    Dins del curs trobaràs:
                    <br />
                    <br />
                    - Cultura general.
                    <br />
                    <br />
                    - Notícies d'actualitat.
                    <br />
                    <br />
                    -Premis
                    <br />
                    <br />
                    - El Municipi.
                    <br />
                    <br />
                    -Dades rellevants
                    <br />
                    <br />
                    - 6 exàmens oficials
                    <br />
                    <br />
                    I Simulacres on et posarem a prova!!
                    <br />
                    <br />
                    Per només 29,99 euros.
                    <br />
                    <br />
                    El curs estarà obert fins al dia de l'examen
                    <br />
                    <br />
                    Entra ara i avança al teu ritme!
                </Text>
            </div>

            {/** ¿Cómo accedo? */}
            <div className="bg-gray-100 bg-opacity-50">
                <div className="container lg:px-0 py-20">
                    <Title>¿Cómo accedo?</Title>
                    <Text className="text-justify">
                        Una vez adquirido a través de la sección{" "}
                        <Link to="/tienda/" className="default-link">
                            Tienda
                        </Link>{" "}
                        recibirás un correo con tus credenciales para poder acceder al curso. En el
                        menú superior encontrarás la opción{" "}
                        <a
                            href="https://cursos.iopos.es"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="default-link"
                        >
                            Plataforma de cursos
                        </a>{" "}
                        que te dirigirá hacia la plataforma donde están almacenados todos los cursos
                        y formaciones.
                    </Text>
                </div>
            </div>
        </ProductTemplate>
    );
};

export default Product;
